<template>
    <div>  
        <div class="container">
        <div style="display:flex;justify-content:flex-end;">
            <el-button v-if="canAdd" type="primary" style="margin-left:30px;height:20px;" icon="el-icon-plus" @click="onNew">新建</el-button>
        </div>
        <el-form ref="scform" label-position="left" :model="scform" label-width="130px">
            <el-row>
            <el-form-item label="上传日期">
                <el-date-picker
                    v-model="scform.dateArray"
                    type="daterange"
                    range-separator="至"
                    value-format="yyyy/MM/dd HH:mm:ss"
                    start-placeholder="年/月/日"
                    end-placeholder="年/月/日">
                </el-date-picker>
            </el-form-item>
            </el-row>
            <el-row>
            <el-form-item label="报告类型">
                <el-select v-model="scform.fileTypeId" style="width:213px;" clearable placeholder="请选择报告类型">
                    <el-option v-for="item in ReportTypeList" :key="item.value" :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="关键字" >
                <el-input v-model="scform.key" style="width:213px;" placeholder="输入关键字" class="handle-input mr10"></el-input>
            </el-form-item>
            <el-button type="primary" style="margin-left:30px;height:20px;" icon="el-icon-search" @click="onPrivateQuery()">搜索</el-button>
            </el-row>
        </el-form>
        <!-- <div style="display:flex;justify-content:center;">
            <el-button type="primary" style="margin-left:30px;height:20px;" icon="el-icon-search" @click="onPrivateQuery()">搜索</el-button>
        </div> -->

        <el-table
                :data="privateList"
                border
                ref="commonTable"
                :header-cell-style="{background:'#eef1f6',color:'#606266'}"
                style="width: 100%;margin-top: 10px;"
                :row-key="getRowKey" stripe 
                @selection-change="handleSelectionChange">
                <el-table-column type="selection" reserve-selection width="55"></el-table-column>	
                <el-table-column prop="projectName" align="center"  label="报告名称"></el-table-column>	
                <el-table-column prop="reportType" align="center"  label="报告类型"></el-table-column>	
                <el-table-column prop="description" align="center"  label="报告描述"></el-table-column>		
                <el-table-column prop="fileSize" align="center"  label="文件大小"></el-table-column>				
                <el-table-column prop="id" align="center" label="文件" width="121">
                    <template v-slot="scope">
                        <el-button type="text" @click="onDownItem(scope.row)">下载</el-button>
                    </template>
                </el-table-column>                            
                <el-table-column prop="rowTime" align="center"   label="创建时间"></el-table-column>
                <el-table-column  v-if="canEdit" label="操作" align="center" width="80px">
                    <template v-slot="scope">
                        <el-tooltip class="item" effect="dark" content="删除" placement="left-start">
                        <i class="el-icon-delete" style="color:red" @click="onDeleteItem(scope.row)"></i>
                        </el-tooltip>
                        <el-tooltip class="item" effect="dark" content="编辑" placement="right-start">
                        <i class="el-icon-edit" style="margin-left:10px;" @click="onEditItem(scope.row)"></i>
                        </el-tooltip>
                        <!-- <el-button type="text" @click="onDeleteItem(scope.row)">删除</el-button>	 -->
                        <!-- <el-button type="text" @click="onEditItem(scope.row)"></el-button>										 -->
                    </template>
                </el-table-column>
        </el-table>

        <el-dialog title="新建报告" v-model="newProjectDialogVisible" width="40%" 
                    :modal-append-to-body='false'
                    :close-on-click-modal='false'                    
                    append-to-body>
                <el-form :model="form">
					<el-form-item label="报告名称">
						<el-input v-model="form.projectName" style="width:213px;" placeholder="输入报告名称" class="handle-input mr10"></el-input>
					</el-form-item>
                    <el-form-item label="报告类型">
                        <el-select v-model="form.fileTypeId" style="width:213px;" clearable placeholder="请选择报告类型">
                            <el-option v-for="item in ReportTypeList" :key="item.value" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="说　　明" style="width: 100%;">
                        <el-input v-model="form.description" type="textarea" autocomplete="off" :rows="3"
                                                                style="width: 615px;"></el-input>
                    </el-form-item>
					<my-upload-file-form-item label="报告文件" :limit="1" @change="getFileArr" :file-list="form.fileArray" v-model="form.fileArray"/>
				</el-form>
                
                <div style="display:flex;justify-content:center;margin-top:20px;">
                    <el-button type="primary" @click="onSavePrivate">确定</el-button>
                    <el-button @click="newProjectDialogVisible=false">取消</el-button>
                </div>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import {getLatestMonthDateArrayByDate,getDateArrayByBoLangXian,getNextMonthDateArray,getLatestThreeMonthDateArray,getLatestOneYearDateArray} from "@/utils/time";
import {getToken} from "@/utils/auth";
import {DelProjectSmart,ProjectExportById,SaveProjectSmart,ProjectListExport} from "@/api/operate";
import MyUploadFileFormItem from "@/components/MyUploadFileFormItem";
import {SavePrivateProject,DelPrivateProject} from "@/api/privateProject";
import {getFileUrl} from "@/api/file";

export default {
    name: 'baseform',
    components:{
        MyUploadFileFormItem
    },
    computed: {...mapGetters(['ReportTypeList','privateProjectList','privateProjectRightList'])},
    data() {
        return {           
            canEdit:false,
            title:'',
            curItem:{},
            detailData:[],            
             // 当前页
            currentPage: 1,
            // 每页多少条
            pageSize: 20,
            ppml:{
                currentPage: 1,
                pageSize: 20,
            },
            
            props: { multiple: false },
            currentProject:{},
            selectedList:[],

            scform:{
                dateArray:getLatestOneYearDateArray(),
                key:'',
                fileTypeId:'',                
            },
            
            privateList:[],
            form: {privateProjectId:'',projectName: '', fileArray: [],description:'',fileTypeId:'',},
            newProjectDialogVisible:false,
            canAdd:false,    //是否可以编辑报告
            canEdit:false,    //是否可以编辑报告
            canDelete:false,    //是否可以编辑报告
        };
    },
    
    async mounted() {
        await this.$store.dispatch('operate/ReportTypeList');        
        const s = document.createElement('script');
        s.type = 'text/javascript';
        s.src = 'http://pv.sohu.com/cityjson?ie=utf-8&ver='+Math.random();
        document.body.appendChild(s);
        // var loginfo = JSON.parse(localStorage.getItem('loginInfo'));
        // if (loginfo.roleId==1 || loginfo.roleId==1037){
        //     this.canEdit=true;
        // }
        this.onPrivateQuery();
    },

    methods: {
      async onPrivateQuery(){
        // const [sYM, eYM] = this.scform.dateArray;   
        const {fileTypeId,dateArray:[sYM, eYM]} =this.scform;   
        // console.log('this.scform',this.scform)
        var tmpTId =  fileTypeId;
        if (tmpTId=="")
            tmpTId="0";  
        var sDate = new Date(sYM).toLocaleDateString();
        var eDate = new Date(eYM).toLocaleDateString();
        await this.$store.dispatch('privateProject/privateProjectList', {sDate,eDate,key:this.scform.key,isPublic:1,fileTypeId:tmpTId}).then((res)=>{
            this.privateList = this.privateProjectList;
            this.canAdd = this.privateProjectRightList[0].canAdd;
            this.canEdit = this.privateProjectRightList[0].canModify;
            this.canDelete = this.privateProjectRightList[0].canDelete;
        });
      },

    onNew(){
        this.form={privateProjectId:'',projectName: '', fileArray: [],description:'',fileTypeId:''};
        this.newProjectDialogVisible=true;
    },

    onEditItem(item){
        this.form=item;
        this.form.fileArray = [{name:item.files,url:item.files}];
        this.newProjectDialogVisible=true;
        console.log('this.form',this.form)
    },

      onDownItem(item) {
		document.location.href = getFileUrl({name: item.files})
	  },

      async onDeleteItem(item) {
        this.$confirm('确认删除'+item.projectName+', 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
        }).then(async () => {
            await DelPrivateProject({userId: getToken(), privateProjectId: item.privateProjectId});
            this.onPrivateQuery();
            this.$message({
                type: 'success',
                message: '删除成功!'
            });
        }).catch(() => {
            // this.$message({
            //     type: 'info',
            //     message: '已取消删除'
            // });          
        });

            // await dealDeleteOperation({deletePromise:DelAdVersion({userId: getToken(), versionId: item.versionId})})
            
    },

    async onSavePrivate(){
		const {privateProjectId,projectName, fileArray: [files],description,fileTypeId} = this.form;
		if (!projectName || !files || !fileTypeId) {
			this.$message.error('请填写完整');
			return;
		}
		await SavePrivateProject({
								userId: getToken(),
								privateProjectId,
                                projectName,
                                description,
								files:files.name,	
                                fileTypeId,
                                isPublic:1			
		});
		this.$message.success('保存成功');
		this.newProjectDialogVisible=false;
		// await this.$store.dispatch('operate/GetProjectSmartList',);
        this.onPrivateQuery();
    },

    getFileArr(item){
        if (item.length || item.length==0){
            this.form.fileArray=item;
        }else{
            // console.log('bb')
        }
        console.log('item111',item,item.length)
     },



      getRowKey(row) {
		return row.privateProjectId;
	  },

      handleSelectionChange(val) {				
		this.selectedList = val;				
	},

    // 每页多少条
    handleSizeChange(val) {
        this.pageSize = val;
    },
    handleppmlSizeChange(val) {
        this.ppml.pageSize = val;
    },
    // 当前页
    handleCurrentChange(val) {
        this.currentPage = val;
    },
    handleppmlCurrentChange(val) {
        this.ppml.currentPage = val;
    },


        async exportData(item,flagId){
            this.flagId=flagId;
            this.curProjectId = item.projectId;
            if (flagId==2){
                this.setPPTDialogVisible=true;

            }else{
                const loading = this.$loading({
                    lock: true,
                    text: '疯狂制作中...',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                
                await ProjectExportById({userId: getToken(),flagId:this.flagId,projectId:item.projectId}).then(()=>{
                    loading.close();
                })
            }
		},

        async onRemove(item) {
            // console.log(item)
            this.$confirm('确认删除'+item.projectName+', 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                
                await DelProjectSmart({userId: getToken(), projectId: item.projectId});
                
                this.$message({
                    type: 'success',
                    message: '删除成功!'
                });

            }).catch(() => {
                // this.$message({
                //     type: 'info',
                //     message: '已取消删除'
                // });          
            });
		},

        open(){
            // console.log('do open')
            this.$nextTick(() => {
            //  执行echarts方法
            
            })
        },
    }
};

</script>